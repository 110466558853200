import React, { useState, useRef } from "react";
import { Modal, Button, Toast, ToastContainer } from "react-bootstrap";
import ButtonCreated from "./buttonAddInstance";
import Loader from "./loader";
import { createInstancia } from "../services/instanciaApi"; // Importe a função createInstancia

const BootstrapModal = ({ fetchInstancias }) => {

  const [showModal, setShowModal] = useState(false);
  const [instanceName, setInstanceName] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState(false);

  // Estados para controlar o Toast
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const inputRef = useRef(null);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setInstanceName("");
    setWarning("");
    setIsValid(false);
    setLoading(false);
  };

  const validateInstanceName = (name) => {
    const validPattern = /^[a-z0-9]+$/;
    if (!validPattern.test(name)) {
      setWarning(
        "O nome da instância deve conter apenas letras minúsculas e números, sem espaços ou caracteres especiais."
      );
      setIsValid(false);
    } else {
      setWarning("");
      setIsValid(true);
    }
  };

  const handleInstanceNameChange = (e) => {
    const name = e.target.value.toLowerCase();
    setInstanceName(name);
    validateInstanceName(name);
  };

  const handleSaveChanges = async () => {
    setLoading(true); // Ativar o estado de carregamento
  
    try {
      const response = await createInstancia(instanceName);
  
      if (response.success) {
        setToastMessage(`Instância "${instanceName}" criada com sucesso!`);
        setShowToast(true);
  
        // Atualiza o Dashboard após a criação bem-sucedida
        fetchInstancias();
  
        setTimeout(() => {
          handleCloseModal(); // Fecha o modal
        }, 5000);
      } else {
        setToastMessage(`Erro: ${response.message}`);
        setShowToast(true);
      }
    } catch (error) {
      console.error("Erro ao criar instância:", error);
      setToastMessage("Erro ao criar instância.");
      setShowToast(true);
    } finally {
      setLoading(false); // Desativar o estado de carregamento
    }
  };
  

  return (
    <>
      <ButtonCreated
        content={"Criar Instância"}
        handleShowModal={handleShowModal}
      />

      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        onShow={() => inputRef.current && inputRef.current.focus()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Criar Instância</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            ref={inputRef}
            className="form-control"
            placeholder="Digite o nome da sua instância..."
            value={instanceName}
            onChange={handleInstanceNameChange}
          />
          {warning && <p className="text-danger mt-2">{warning}</p>}
        </Modal.Body>
        <Modal.Footer>
          {/* Botão "Fechar" removido enquanto estiver salvando */}
          {!loading && !showToast && (
            <Button variant="secondary" onClick={handleCloseModal}>
              Fechar
            </Button>
          )}

          {/* Botão de salvar alterado */}
          <Button
            variant="primary"
            onClick={handleSaveChanges}
            disabled={!isValid || loading || showToast} // Desabilita o botão enquanto estiver carregando ou mostrando o Toast
          >
            {loading ? "Salvando..." : "Salvar"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Spinner Overlay */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <Loader />
        </div>
      )}

      {/* Toast de sucesso ou erro */}
      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={5000}
          autohide
          bg={toastMessage.startsWith("Erro") ? "danger" : "success"}
        >
          <Toast.Body className="text-white">{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default BootstrapModal;
