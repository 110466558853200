import "../css/statistics.css";

function CardStatistics({ content, title, svg }) {
  return (
    <div className={`statistics-card-wrapper`}>
      <div className="statistics-card">
        <img src={svg} alt="" />
        <div>
          <p>{title}</p>
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
}

export default CardStatistics;
