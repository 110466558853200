import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import constructionImage from "../images/robotrabalhando.png"; // Substitua pelo caminho correto da sua imagem
import "../css/templates.css"

function Tablefluxo() {
  /* const [tabs, setTabs] = useState([
    {
      id: 1,
      label: "Dia 1",
      isActive: true,
      isDisabled: false,
      content: "Conteúdo do Dia 1",
    },
    { id: "add-tab", label: "+", isActive: false, isDisabled: false },
  ]);

  const handleAddTab = () => {
    const realTabs = tabs.filter((tab) => tab.id !== "add-tab");
    const newTabId = realTabs.length + 1;

    setTabs([
      ...realTabs.map((tab) => ({ ...tab, isActive: false })), // Desativa todas as abas reais
      {
        id: newTabId,
        label: `Dia ${newTabId}`,
        isActive: true, // Ativa a nova aba
        isDisabled: false,
        content: `Conteúdo do Dia ${newTabId}`,
      },
      { id: "add-tab", label: "+", isActive: false, isDisabled: false },
    ]);
  }; */

  /* const handleTabClick = (tabId) => {
    setTabs(
      tabs.map((tab) =>
        tab.id === tabId
          ? { ...tab, isActive: true }
          : { ...tab, isActive: false }
      )
    );
  }; */

  return (
    <div className="construction-container">
      <img
        src={constructionImage}
        alt="Em construção"
        className="construction-image"
      />
      <h2>Estamos em Construção!</h2>
      <p>Em breve, teremos novidades incríveis para você. Fique atento!</p>
    </div>
  );
}

export default Tablefluxo;
