import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import { generateQRCode } from "../services/qrcodeApi";
import QrCodeModal from "./modalQrcode";

function InputNumberValidation({
  instanceKey,
  instanceToken,
  instanceBase_url,
  status, // Recebe o status da instância
}) {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [qrCode, setQrCode] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Função para atualizar o número de telefone
  const handleOnChange = (value) => {
    setPhone(value);
    setError(null);
  };

  // Função para gerar o QR Code
  const handleGenerateQrCode = async () => {
    if (status === "on") {
      setError("A instância já está conectada.");
      return;
    }

    if (!phone || phone.length < 10) {
      setError("Número de telefone inválido.");
      return;
    }

    if (!instanceKey || !instanceToken || !instanceBase_url) {
      setError("Chave da instância ou token inválidos.");
      console.error("Erro: Chave ou token da instância não fornecidos.");
      return;
    }

    try {
      const requestData = {
        contact: phone, // Número de telefone
        key: instanceKey, // Chave da instância
        token: instanceToken, // Token da instância
        base_url: instanceBase_url,
      };

      /*       console.log("Enviando dados para a API:", requestData); */

      // Chama a API de QR Code com os parâmetros necessários
      const response = await generateQRCode(requestData);

      if (response.success && response.data) {
        /*       console.log("QR Code gerado com sucesso:", response.data); */
        setQrCode(response.data.qrcode);
        setShowModal(true); // Exibe o modal
      } else {
        setError(response.message || "Erro ao gerar o QR Code.");
        console.error("Erro ao gerar QR Code:", response.message);
      }
    } catch (error) {
      setError("Erro ao processar a requisição. Tente novamente.");
      console.error("Erro na API de QR Code:", error);
    }
  };

  // Fecha o modal e limpa o QR Code
  const handleCloseModal = () => {
    setShowModal(false);
    setQrCode(null); // Limpa o QR Code ao fechar
  };
  const isButtonDisabled =
    status === "on" || !instanceKey || !instanceToken || !instanceBase_url;

  return (
    <form className="container-validation-form">
      {/* Exibe a instância atualmente selecionada */}

      <span>Digite seu número de telefone</span>
      <PhoneInput
        className="input-phone"
        country={"br"}
        value={phone}
        onChange={handleOnChange}
        enableAreaCodes={true}
        enableSearch={true}
        placeholder="Digite seu número de telefone"
        inputProps={{
          name: "telefone",
          required: true,
          autoFocus: true,
        }}
        inputStyle={{
          width: "100%",
          height: "50px",
        }}
      />
      {error && <div style={{ color: "red" }}>{error}</div>}
      <div>
        <button
          className="btn-generateQrCode"
          type="button"
          onClick={handleGenerateQrCode}
          disabled={isButtonDisabled} // Desabilita se a instância estiver conectada ou se as chaves não forem fornecidas
        >
          <i className="animation"></i> Gerar Qrcode{" "}
          <i className="animation"></i>
        </button>
      </div>
      {/* Modal do QR Code */}
      <QrCodeModal
        show={showModal}
        base64={qrCode}
        onClose={handleCloseModal}
        Key={instanceKey} // Passa a chave da instância para o modal
        Token={instanceToken} // Passa o token da instância para o modal
        base_url={instanceBase_url}
      />
    </form>
  );
}

export default InputNumberValidation;
