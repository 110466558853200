import React from "react";
import "../css/canaisInfo.css"; // Certifique-se de ter o CSS adequado para os cards

function CardPlano({ title, description, price }) {
  return (
    <div className="card-plan">
      <h3>{title}</h3>
      <p>{description}</p>
      <strong>{price}</strong>
    </div>
  );
}

export default CardPlano;
