import React from "react";
import "../css/cardFooter.css"; // Ajuste o caminho conforme necessário
import { ReactComponent as EnvelopeIcon } from "../images/images-login/envelope.svg"; // Ajuste o caminho
import pixIcon from "../images/images-login/pix.png"; // Ajuste o caminho conforme necessário

function FooterLink({ url, text }) {
  return (
    <a href={url} className="footer-link">
      {text}
    </a>
  );
}

function FooterInfoItem({ title, text }) {
  return (
    <div className="footer-info-content">
      <p className="footer-info-title">{title}</p>
      <p className="footer-info-text">{text}</p>
    </div>
  );
}

function Footer() {
  const links = [
    { text: "Termos & Condições", url: "/terms" },
    { text: "Preços", url: "/pricing" },
    { text: "Sobre nós", url: "/about" },
  ];

  return (
    <footer id="footer" className="section section-footer">
      <div className="footer-container">
        {/* Contato */}
        <div className="contact-info">
          <a
            href="mailto:suporte@megasenderstore.com"
            className="contact-info-link"
          >
            <span className="contact-info-icon">
              <EnvelopeIcon width={16} height={16} />
            </span>
            <span className="contact-info-text">
              Email: suporte@megasenderstore.com
            </span>
          </a>
        </div>

        <hr className="footer-divider" />

        {/* Informações gerais */}
        <div className="footer-info">
          <div className="footer-info-content-wrapper">
            <FooterInfoItem title="Megasender" text="São Paulo, Brasil" />

            {/* Links de navegação */}
            <div className="footer-links">
              {links.map((link, index) => (
                <FooterLink key={index} url={link.url} text={link.text} />
              ))}
            </div>

            {/* Informação PIX */}
            <div className="footer-pix">
              <img src={pixIcon} alt="PIX" />
              <p>Aceitamos pagamentos via PIX</p>
            </div>
          </div>

          <p style={{ textAlign: "right" }}>
            Copyright - 2024 Todos os direitos reservados
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
