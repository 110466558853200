import "../css/servicesMega.css";

function CardServices({ content, title, svg, position }) {
  return (
    <div className={`services-card ${position}-item`}>
      <h3>
        {/* Suporte 24/7 */}
        {title}
      </h3>
      <p>
        {/* Tenha suporte Inteligente diretamente no Software ou humano
              através do WPP, Telegram e Discord. */}
        {content}
      </p>
      <div className="services-card-footer">
        <img src={svg} alt="" />
        </div>
        
    </div>
  );
}

export default CardServices;
