import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import InstanceView from "../pages/intanceConfigure";
import Login from "../pages/login";
import Plans from "../pages/planos";

const MegaSenderRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  // Verifica a autenticação assim que o componente for montado
  useEffect(() => {
    const token = localStorage.getItem("userToken"); // Alterado para "userToken"
    setIsAuthenticated(Boolean(token));
  }, []); // Isso só será executado uma vez após o componente ser montado

  // Enquanto o estado de autenticação estiver indefinido (null), renderiza uma tela de carregamento
  if (isAuthenticated === null) {
    return <div>Carregando...</div>; // Exibe enquanto a verificação de autenticação está sendo feita
  }

  return (
    <Routes>
      {/* Redireciona para o login ou dashboard com base na autenticação */}
      <Route
        path="/"
        element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} />}
      />
      {/* Rota pública para InstanceView */}
      <Route path="/instanceView/:actionType" element={<InstanceView />} />
      {/* Rota pública para Login - apenas se não estiver autenticado */}
      <Route
        path="/login"
        element={
          isAuthenticated ? (
            <Navigate to="/dashboard" />
          ) : (
            <Login setIsAuthenticated={setIsAuthenticated} />
          )
        }
      />
      {/* Rota privada para Dashboard */}
      <Route
        path="/dashboard"
        element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
      />
      <Route path="/plans" element={<Plans />} />
      {/* Rota para a página de planos */}
    </Routes>
  );
};

export default MegaSenderRoutes;
