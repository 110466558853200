import React from "react";
import "../css/canaisInfo.css";
import whatsapp from "../images/whatsapp.png";
import telegram from "../images/telegran.png";
import insta from "../images/instagran.png";
import discord from "../images/discord.png";
import meet from "../images/meet.png";

function CardInfor({ content, img, text, urlInfo }) {
  return (
    <a
      href={urlInfo}
      className="blog-card"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={img} alt={content} />
      <div className="blog-card-content">
        <h3>{content}</h3>
        <div className="blog-card-text-wrapper">
          <p>{text}</p>
        </div>
      </div>
    </a>
  );
}

function CanaisInfo() {
  const canais = [
    {
      content: "Canal no WhatsApp",
      img: whatsapp,
      text: "Acesse nosso canal do WhatsApp para estar por dentro de todas nossas atualizações e estratégias para te ajudar a escalar ainda mais as suas vendas através do WhatsApp.",
      urlInfo: "https://www.whatsapp.com/channel/0029VaXaMex0LKZC0wrSDb0S",
    },
    {
      content: "Canal no Telegram",
      img: telegram,
      text: "Acesse nosso Telegram para estar por dentro de todas as nossas atualizações e também todas as promoções que temos em nossa loja.",
      urlInfo: "https://t.me/megasenderr",
    },
    {
      content: "Instagram",
      img: insta,
      text: "Em nosso perfil do Instagram, passamos atualizações, fazemos sorteios e caixinhas de pergunta para entender como podemos ajudar vocês melhor.",
      urlInfo: "https://www.instagram.com/megasender_",
    },
    {
      content: "Discord",
      img: discord,
      text: "Apenas para membros*\nTemos nosso Discord exclusivo para suporte e fazemos lives para conversarmos sobre operações que precisam de WhatsApp e como explorar ainda mais esse mercado tanto no Brasil como na Europa e nos EUA.",
      urlInfo: "https://discord.com/invite/seu-link",
    },
    {
      content: "Meet",
      img: meet,
      text: "Apenas para membros*\nEm nosso Google Meet, fazemos call de networking todas as semanas para ouvir nossos clientes e entender como podemos ajudar ainda mais a estruturar suas operações através do WhatsApp.",
      urlInfo: "https://meet.google.com/seu-link",
    },
  ];

  return (
    <div className="container blogs-container">
      <h2>Nossos canais de comunicação</h2>
      <div className="blog-card-wrapper">
        {canais.map((canal, index) => (
          <CardInfor
            key={index}
            content={canal.content}
            img={canal.img}
            text={canal.text}
            urlInfo={canal.urlInfo}
          />
        ))}
      </div>
    </div>
  );
}

export default CanaisInfo;
