import React from "react";
import { Helmet } from "react-helmet";
import robotGif from "../images/images-login/robot.gif";

const Chatbot = () => {
  return (
    <div>
      <Helmet>
        <style>{`
          df-messenger {
            --df-messenger-bot-message: #bdc1cf;
            --df-messenger-button-titlebar-color: #04720d;
            --df-messenger-chat-background-color: #fafafa;
            --df-messenger-font-color: rgb(29, 26, 26);
            --df-messenger-send-icon: #afb2b8;
            --df-messenger-user-message: #b9bcc2;
            --df-messenger-input-font-color: black;
            --df-messenger-input-placeholder-font-color: white;
          }

          df-messenger .df-chat-icon {
            animation: rotate 4s linear infinite;
          }

          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }

            100% {
              transform: rotate(360deg);
            }
          }
        `}</style>
        <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>
      </Helmet>
      <df-messenger
        intent="WELCOME"
        chat-title="MegaSender"
        agent-id="0b9a4b48-8e46-4eb0-a278-55f2a116764f"
        language-code="pt-br"
        chat-icon={robotGif}
      ></df-messenger>
    </div>
  );
};

export default Chatbot;
