import React, { useState } from "react";
import styled from "styled-components";
import { verifyInstance } from "../services/qrcodeApi";

const ButtonSinc = ({ instanceKey, instanceToken, instanceBase_url , onResult, onCloseModal }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSync = async () => {
    if (!instanceKey || !instanceToken || !instanceBase_url) {
      setError("Os parâmetros 'key' e 'token' são obrigatórios.");
      console.error("Erro: 'key' ou 'token' ausentes.");
      return;
    }

    try {
      setLoading(true); // Inicia o carregamento
      setError(null);

      console.log("Sincronizando com os dados:", {
        key: instanceKey,
        token: instanceToken,
        base_url: instanceBase_url


      });

      const response = await verifyInstance({
        key: instanceKey,
        token: instanceToken,
        base_url: instanceBase_url
      });

      console.log("Resposta da API de verificação:", response);

      if (response.success) {
        onResult?.({
          imageUrl: response.data.result, // URL da imagem
          isValid: response.data.isValid, // Status
        });

        // Fecha o modal após 20 segundos
        setTimeout(() => {
          onCloseModal?.(); // Chama a função para fechar o modal
        }, 5000);
      } else {
        setError(response.message || "Erro desconhecido ao sincronizar.");
      }
    } catch (err) {
      console.error("Erro inesperado:", err);
      setError("Erro inesperado. Tente novamente.");
    } finally {
      setLoading(false); // Finaliza o carregamento
    }
  };

  return (
    <StyledWrapper>
      {/* <button className="button" disabled>
        {loading ? "Sincronizando..." : "Sincronizar"}
      </button> */}
      {error && <div className="error">{error}</div>}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .button {
    position: relative;
    overflow: hidden;
    height: 2.3rem;
    width: 100%;
    padding: 0 2rem;
    border-radius: 0.5rem;
    background: #3d3a4e;
    background-size: 400%;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 14pt;
    transition: background-color 0.3s ease;
  }

  .button:disabled {
    background: #555;
    cursor: not-allowed;
  }

  .button:hover::before {
    transform: scaleX(1);
  }

  .button-content {
    position: relative;
    z-index: 1;
  }

  .button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    width: 100%;
    height: inherit;
    border-radius: inherit;
    background: linear-gradient(
      82.3deg,
      rgba(150, 93, 233, 1) 10.8%,
      rgba(99, 88, 238, 1) 94.3%
    );
    transition: all 0.475s;
  }

  .result {
    margin-top: 1rem;
    text-align: center;

    .qr-code {
      margin-top: 0.5rem;
      max-width: 200px;
      max-height: 200px;
    }
  }

  .error {
    margin-top: 1rem;
    color: red;
    font-weight: bold;
  }
`;

export default ButtonSinc;
