import React from "react";
import styled from "styled-components";

const ButtonAquece = ({ onClick, content = "AQUECER", loading }) => {
  return (
    <StyledWrapper>
      <button
        id="btn-aquecimento"
        className="btn btn-outline-success"
        onClick={() => {
          console.log("Botão AQUECER clicado."); // Log para depuração
          if (onClick) onClick(); // Executa a função passada como prop
        }}
        disabled={loading} // Desabilita o botão durante o carregamento
      >
        {loading ? "Enviando..." : content}
      </button>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  #btn-aquecimento {
    font-family: "Inria Sans", sans-serif;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    transition: all 0.3s ease;
    background-color: white; /* Fundo branco */
    color: #28a745; /* Texto com a cor verde */
    border: 1px solid #28a745; /* Borda verde */
    padding: 10px 20px; /* Tamanho */
    cursor: pointer;
    border-radius: 5px; /* Borda arredondada */
  }

  #btn-aquecimento:hover {
    background-color: #28a745; /* Fundo verde no hover */
    color: white; /* Texto branco no hover */
    border-color: #28a745; /* Borda verde no hover */
    transition: all 0.3s ease;
  }

  #btn-aquecimento:disabled {
    background-color: #f8f9fa; /* Fundo cinza claro ao desabilitar */
    color: #cccccc; /* Texto cinza claro ao desabilitar */
    border-color: #cccccc; /* Borda cinza claro ao desabilitar */
    cursor: not-allowed; /* Cursor não permitido */
  }
`;

export default ButtonAquece;
