import React from "react";
import "../css/herderLogin.css";
import ButtonL from "./buttonLogin";

function HeaderLogin({ title }) {
  const navigateToPlans = () => {
    window.location.href = "/plans"; // Redireciona para a página de planos
  };

  return (
    <section id="header" className="section section-header">
      <div className="header-container" style={{ height: "100%" }}>
        <h2
          onClick={() => (window.location.href = "/login")}
          style={{ cursor: "pointer" }}
        >
          {title}
        </h2>
        <div className="button-container">
          {/* Chamando a função ao clicar no botão */}
          <span onClick={navigateToPlans}>
            <ButtonL children={"Conheça nossos planos"} />
          </span>
        </div>
      </div>
    </section>
  );
}

export default HeaderLogin;
