import { useState, useEffect } from "react";
import { Toast, ToastContainer } from "react-bootstrap"; // Certifique-se de instalar react-bootstrap
import roboFresco from "../images/robo-animation.gif";
import ButtonAquece from "../components/buttonAquece"; // Importa o botão separado
import { createFluxo } from "../services/fluxo_aqueApi"; // Serviço API para criar fluxo
import "../css/personaliza.css";

const Personalizar = ({ tokenInstance }) => {
  const [erro, setErro] = useState("");
  const [valorValido, setValorValido] = useState(false);
  const [days, setDays] = useState(""); // Número de dias inserido pelo usuário
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [showToast, setShowToast] = useState(false); // Controle do Toast
  const [toastMessage, setToastMessage] = useState(""); // Mensagem do Toast

  // Confirma que a instância selecionada está correta
  useEffect(() => {
    if (tokenInstance) {
    
    } else {
      console.error("Nenhuma instância foi selecionada.");
    }
  }, [tokenInstance]);

  const validarInput = (event) => {
    const valor = event.target.value;
    setDays(valor); // Atualiza o valor do input no estado
    if (
      !/^\d{1,3}$/.test(valor) ||
      parseInt(valor) < 1 ||
      parseInt(valor) > 100
    ) {
      setErro("Por favor, insira um número entre 1 e 100.");
      setValorValido(false); // Número inválido
    } else {
      setErro("");
      setValorValido(true); // Número válido
    }
  };

  const enviarFluxo = async () => {
    if (!tokenInstance) {
      console.error("Token da instância não encontrado.");
      setToastMessage("Erro: Nenhuma instância selecionada.");
      setShowToast(true);
      return;
    }

    try {
      setLoading(true); // Inicia o estado de carregamento

      const payload = {
        token_instance: tokenInstance,
        days: parseInt(days),
        media: null,
        type: "group",
        destinos: null,
      };



      const result = await createFluxo(payload.token_instance, payload.days);

      if (result.success) {
        setToastMessage("Aquecimento criado com sucesso!");
        setShowToast(true);
        console.log("Resposta da API:", result.data);
      } else {
        setToastMessage(`Erro ao criar o aquecimento: ${result.message}`);
        setShowToast(true);
        console.error("Erro da API:", result.message);
      }
    } catch (error) {
      setToastMessage("Erro ao enviar o fluxo. Tente novamente.");
      setShowToast(true);
      console.error("Erro inesperado durante o envio:", error);
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <div className="container-pensonalization-simples">
      <h3>Configuração Rápida</h3>

      <div className="container-fluxosSimples">
        <div className="form-later-fluxos">
          <label id="indicatorDays" className="indicatorDays">
            Insira o número de dias do fluxo
          </label>
          <input
            className="form-control"
            type="text"
            name="fluxoDays"
            id="fluxoDays"
            value={days} // Controle de valor do input
            onInput={validarInput}
            pattern="\d{1,3}"
            required
          />

          {/* Exibe a mensagem de erro se existir */}
          {erro && (
            <p style={{ color: "red", fontSize: "12px", padding: "10px 0px" }}>
              {erro}
            </p>
          )}

          {/* Usa o botão separado */}
          {valorValido && (
            <div className="button-aquecime">
              <ButtonAquece
                onClick={enviarFluxo} // Passa a função de envio ao botão
                loading={loading} // Passa o estado de carregamento
                content={"AQUECER"} // Texto do botão
              />
            </div>
          )}
        </div>

        {/* <div className="informativo">
          <img id="robo-fresco" src={roboFresco} alt="Robô animado" />
        </div> */}
      </div>

      {/* Toast para notificações */}
      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={5000}
          autohide
          bg={toastMessage.startsWith("Erro") ? "danger" : "success"}
        >
          <Toast.Body className="text-white">{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default Personalizar;
