import React, { useState, useEffect } from "react";
import ButtonSinc from "./sicronize_button_instance";

const QrCodeModal = ({ show, base64, onClose, Key, Token, base_url }) => {
  const [qrScanned, setQrScanned] = useState(false); // Indica se o QR Code foi escaneado

  useEffect(() => {
    // Simula o escaneamento automático após 3 segundos (substitua por lógica real)
    if (base64) {
      const timer = setTimeout(() => {
        console.log("QR Code escaneado automaticamente.");
        setQrScanned(true);

        // Fecha o modal automaticamente após 20 segundos do escaneamento
        const closeModalTimer = setTimeout(() => {
          console.log("Fechando modal automaticamente após 20 segundos.");
          onClose?.(); // Chama a função para fechar o modal
        }, 5000);

        return () => clearTimeout(closeModalTimer); // Limpa o timer ao desmontar o componente
      }, 30000);

      return () => clearTimeout(timer); // Limpa o timer ao desmontar o componente
    }
  }, [base64, onClose]);

  if (!show) return null;

  return (
    <div
      className="modal fade show"
      tabIndex={-1}
      style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ border: "none" }}>
          <div
            className="modal-header editing-modal-qrcode"
            style={{ border: "none" }}
          >
            <button type="button" className="btn-close" onClick={onClose} />
          </div>
          <div
            id="modal-body-qrcode"
            className="modal-body editing-modal-qrcode"
          >
            {base64 ? (
              <>
                <img src={base64} alt="QR Code" />
                <div className="infomations">
                  <div className="info-texts">
                    <div className="info-pass">
                      <i className="fa-solid fa-1"></i>{" "}
                      <span>Aponte sua câmera para o QR Code na tela</span>
                    </div>
                    <div className="info-pass">
                      <i className="fa-solid fa-2"></i>{" "}
                      <span>Aguarde a conexão e a sincronização</span>
                    </div>
                    <div className="info-pass">
                      <i className="fa-solid fa-3"></i>{" "}
                      <span>Confirme para conectar</span>
                    </div>
                  </div>

                  {/* Exibe o botão de sincronização após o escaneamento automático */}
                  {qrScanned ? (
                    <ButtonSinc
                      instanceKey={Key} // Certifique-se de que "Key" não está undefined
                      instanceToken={Token} // Certifique-se de que "Token" não está undefined
                      InstanceBase_url={base_url}
                      onResult={(result) => result}
                      onCloseModal={onClose} // Fecha o modal
                    />
                  ) : (
                    <p style={{ marginTop: "1rem" }}>
                      Escaneie o código abaixo com o seu Whatsapp para conectar
                      à instância e aguarde 30 segundos.
                    </p>
                  )}
                </div>
              </>
            ) : (
              <p>Erro ao carregar QR Code</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCodeModal;
