import axios from "axios";

// Criando uma instância do axios
const api = axios.create({
  baseURL: "https://megasenderbackend.tech/megasender", // URL base da API
  timeout: 10000, // Tempo limite para a requisição (10 segundos)
});

// Interceptor para adicionar o token no cabeçalho das requisições
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Interceptor para lidar com respostas e renovação do token
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const refreshToken = localStorage.getItem("refresh_token");
      if (refreshToken) {
        try {
          // Tentativa de renovar o token
          const { data } = await api.post("/refresh-token", {
            refresh_token: refreshToken,
          });

          // Salvar novos tokens
          localStorage.setItem("access_token", data.access_token);
          if (data.refresh_token) {
            localStorage.setItem("refresh_token", data.refresh_token);
          }

          // Reenviar a requisição original com o novo token
          error.config.headers.Authorization = `Bearer ${data.access_token}`;
          return api.request(error.config);
        } catch (refreshError) {
          console.error("Erro ao renovar o token:", refreshError);
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          // Redirecionar o usuário para a tela de login caso o refresh falhe
          window.location.href = "/login"; // Exemplo de redirecionamento
        }
      }
    }

    return Promise.reject(error); // Rejeitar o erro se não for possível renovar o token
  }
);

export const loginUser = async (username, password) => {
  try {
    const data = new URLSearchParams();
    data.append("username", username);
    data.append("password", password);

    const response = await api.post("/login", data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    if (response.data && response.data.access_token) {
      localStorage.setItem("access_token", response.data.access_token);
      if (response.data.refresh_token) {
        localStorage.setItem("refresh_token", response.data.refresh_token);
      }

      return {
        success: true,
        token: response.data.access_token,
        user: response.data.user_email,
      };
    } else {
      return { success: false, message: "Login falhou" };
    }
  } catch (error) {
    return handleApiError(error);
  }
};

// Tratamento de erros
const handleApiError = (error) => {
  let errorMessage = "Erro desconhecido";
  if (error.response) {
    console.error("Erro do servidor:", error.response.data);
    errorMessage =
      error.response.data.detail ||
      error.response.data.message ||
      "Erro desconhecido";
  } else if (error.request) {
    console.error("Sem resposta do servidor:", error.request);
    errorMessage =
      "Não foi possível se conectar ao servidor. Verifique sua conexão.";
  } else {
    console.error("Erro na configuração da requisição:", error.message);
    errorMessage = "Erro ao configurar a requisição: " + error.message;
  }

  return { success: false, message: errorMessage };
};

export default api;


