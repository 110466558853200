import "../css/missaoViValo.css"; // Certifique-se de que o caminho para o CSS está correto
import React from "react";
import missaoImg from "../images/images-login/missao.png";
import visaoImg from "../images/images-login/visao.png";
import valorImg from "../images/images-login/valor.png";

function CardMissao() {
  return (
    <section
      id="mission-vision-values"
      className="section section-mission-vision-values"
    >
      <div className="container">
        <div className="mission-vision-values-header">
          <h2>Missão, Visão e Valores</h2>
        </div>
        <div className="mission-vision-values-wrapper">
          {/* Missão */}
          <div className="mission">
            <img src={missaoImg} alt="Missão - Colaboração" />
            <h3>Missão</h3>
            <p>
              Nossa missão é oferecer soluções tecnológicas que potencializam as
              comunicações digitais de nossos clientes, melhorando o desempenho
              e a usabilidade do software, assegurando resultados rápidos e de
              qualidade.
            </p>
          </div>

          {/* Visão */}
          <div className="vision">
            <img src={visaoImg} alt="Visão - Futuro" />
            <h3>Visão</h3>
            <p>
              Ser reconhecidos como a principal plataforma de disparo de
              mensagens e aquecimento de WhatsApp no mercado, garantindo
              segurança e satisfação dos clientes, ajudando negócios a crescerem
              com uma comunicação ágil e eficaz.
            </p>
          </div>

          {/* Valores */}
          <div className="values">
            <img src={valorImg} alt="Valores - Integridade e Excelência" />
            <h3>Valores</h3>
            <p>
              Inovação: Estamos à frente, que transformam o mercado. Segurança:
              Protegemos os dados dos clientes e garantimos confidencialidade.
              Compromisso com o Cliente: Nossa meta é a satisfação, sempre
              superando expectativas.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CardMissao;
