import axios from "axios";

// Criando uma instância do axios com a baseURL
const api = axios.create({
  baseURL: "https://megasenderbackend.tech/megasender",
  timeout: 10000, // Timeout de 10 segundos
});

// Função para gerar QR Code
export const generateQRCode = async ({ contact, key, token, base_url }) => {
  try {
    // Configurando os dados da requisição
    const data = {
      contact,
      key,
      token,
      base_url,
    };

    /* console.log("Enviando dados para gerar QR Code:", data); */

    // Fazendo a requisição POST para a rota especificada
    const response = await api.post("/generate_qrcode", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken") || ""}`, // Token opcional
      },
    });

    // Retornando os dados da resposta da API
    return { success: true, data: response.data };
  } catch (error) {
    console.error("Erro ao gerar QR Code:", error);
    return handleApiError(error);
  }
};

// Função para verificar instância
export const verifyInstance = async ({ key, token, base_url }) => {
  try {
    if (!key || !token || !base_url) {
      throw new Error("Os parâmetros 'key' e 'token' são obrigatórios.");
    }

    // Configurando os dados para envio
    const data = { key, token, base_url };
   

    // Fazendo a requisição POST para a rota /verify_instance
    const response = await api.post("/verify_instance", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken") || ""}`, // Token opcional
      },
    });
  /*   console.log(data); */
    // Retornando os dados da resposta
    return {
      success: true,
      
      data: {
        imageUrl: response.data.result, // URL da imagem
        isValid: response.data.isValid, // Status de validação
      },
    };
  } catch (error) {
    console.error("Erro ao verificar a instância:", error);
    return handleApiError(error);
  }
};

// Função para tratar erros
const handleApiError = (error) => {
  let errorMessage = "Erro desconhecido.";

  if (error.response) {
    // Erro retornado pelo servidor
    errorMessage = error.response.data.message || "Erro no servidor.";
    console.error("Erro do servidor:", error.response.data);
  } else if (error.request) {
    // Sem resposta do servidor
    errorMessage = "Sem resposta do servidor. Verifique a conexão.";
    console.error("Erro de requisição:", error.request);
  } else {
    // Erro de configuração
    errorMessage = `Erro: ${error.message}`;
    console.error("Erro de configuração:", error.message);
  }

  return { success: false, message: errorMessage };
};
