import React from "react";
import constructionImage from "../images/robotrabalhando.png"; // Substitua pelo caminho correto da sua imagem
import "../css/templates.css"

const ContainerTempates = () => {
  return (
    <div className="construction-container">
      <img
        src={constructionImage}
        alt="Em construção"
        className="construction-image"
      />
      <h2>Estamos em Construção!</h2>
      <p>Em breve, teremos novidades incríveis para você. Fique atento!</p>
    </div>
  );
};

export default ContainerTempates;
