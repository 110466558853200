import React, { useState } from "react";
import { Modal } from "react-bootstrap"; // Se você quiser usar React Bootstrap
import styled from "styled-components";

const ModalErro = ({ message, onClose }) => {
  const [showModal, setShowModal] = useState(true); // Modal começa visível

  const handleCloseModal = () => {
    setShowModal(false);
    if (onClose) onClose(); // Garante o fechamento pelo prop
  };

  return (
    <StyledModal show={showModal} onHide={handleCloseModal}>
      <ModalBody>
        <span>Email ou Senha Incorreto!</span>
        <CloseButton onClick={handleCloseModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 20 20"
          >
            <path
              fill="#ffff"
              d="m15.8333 5.34166-1.175-1.175-4.6583 4.65834-4.65833-4.65834-1.175 1.175 4.65833 4.65834-4.65833 4.6583 1.175 1.175 4.65833-4.6583 4.6583 4.6583 1.175-1.175-4.6583-4.6583z"
            />
          </svg>
        </CloseButton>
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .modal-content {
     background: rgba(208, 0, 0,0.5);
    border-radius: 8px;
    box-shadow: 0px 0px 5px -3px #111;
    border: 1px solid #D00000;
  }
`;
const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const ModalBody = styled(Modal.Body)`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
`;




export default ModalErro;
