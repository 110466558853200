import React, { useState } from 'react';
import styled from 'styled-components';

const ButtonCreated = ({handleShowModal, content }) => {

  return (
    <>
      <StyledWrapper>
        <button
          id="create_instance"
          className="btn btn-outline-success"
          onClick={handleShowModal}
        >
        {content}
        </button>
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  #create_instance {
    font-family: 'Inria Sans', sans-serif;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }

  #create_instance:hover {
    letter-spacing: 1.5px;
    transition: all 0.3s ease;
  }
`;

export default ButtonCreated;
/*    */