import "../css/sobreMega.css"; // Certifique-se de que o caminho para o CSS está correto
import React from "react";

function SobreMega() {
  return (
    <section id="section-features" className="section section-features">
      <div className="features-container container">
        <h2 className="features-title">Sobre o MegaSender/ Soluções</h2>
        <div className="features-wrapper">
          <div className="features-card">
            <p className="features-text">
              O 1º e unico Software que aquece whatsapp com apenas 3 cliques, o
              mesmo já aqueceu e preparpou mais de 30 mil Wpp, para fazer
              disparos em massa e receber leads
            </p>
          </div>
          <div className="features-card">
            <p className="features-text">
              Em nossos Canais de Comunicação passamos estratégias pra voce
              vender mais e escalar aind amais as sua operações
            </p>
          </div>
          <div className="features-card">
            <p className="features-text">
              Compartilhe seu Login com sua equipe para que todos acessem
              software 100% web não precisa intalar em seu computador
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default SobreMega;
