import React, { useState, useEffect } from "react";
import "../css/cardMega.css";
import imagemPc from "../images/images-login/pc.png";
import imagemGb from "../images/images-login/grupos.png";

function CardMega() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [imagemPc, imagemGb]; // Array de imagens
  const interval = 5000; // 5 segundos

  useEffect(() => {
    // Configura o intervalo para alternar as imagens
    const toggleImage = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const intervalId = setInterval(toggleImage, interval);

    // Limpa o intervalo ao desmontar o componente
    return () => clearInterval(intervalId);
  }, [images.length]);

  return (
    
      <div className="container target-container">
        <div className="target-wrapper">
          <h2>O melhor Software de maturação do mercado</h2>
          <p>
            Aquecemos seu wpp com um clique, depois você pode disparar em nosso
            megadisparos.
            <br />
            Se você não tiver chip cadastrado, temos já pronto.
            <br />
            Se você não tiver wpp aquecido, temos já pronto.
            <br />
            Se você não tiver leads, temos.
            <br />
            Se você não tiver funil, temos.
          </p>
        </div>
        <div className="image-wrapper">
          {images.map((image, index) => (
            <img
              key={index}
              className={`zoom-in img-fluid ${
                index === currentImageIndex ? "zoom-in-active" : ""
              }`}
              src={image}
              alt={`Imagem ${index + 1}`}
            />
          ))}
        </div>
      </div>
    
  );
}

export default CardMega;
