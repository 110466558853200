import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

function ProgressCircle({ dataInicio, dataTermino, status }) {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    // Se a instância estiver desconectada ou sem data de início, o progresso será 0
    if (!dataInicio || status !== "on") {
      setPercentage(0);
      return;
    }

    const startDate = new Date(dataInicio);
    const endDate = new Date(dataTermino);
    const totalDuration = endDate - startDate;

    if (totalDuration <= 0) {
      setPercentage(100); // Caso a data final já tenha passado
      return;
    }

    const updateProgress = () => {
      const now = new Date();
      const elapsedTime = now - startDate;

      if (elapsedTime >= totalDuration) {
        setPercentage(100); // Progresso completo
      } else {
        const progress = Math.min((elapsedTime / totalDuration) * 100, 100);
        setPercentage(progress.toFixed(0));
      }
    };

    updateProgress();

    const interval = setInterval(updateProgress, 1000);

    return () => clearInterval(interval);
  }, [dataInicio, dataTermino, status]);

  return (
    <div style={{ width: 35, height: 35, margin: "0 auto" }}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          pathColor: percentage >= 100 ? "#4caf50" : "#e85d04",
          textColor: percentage >= 100 ? "#4caf50" : "#e85d04",
          trailColor: "#d6d6d6",
          textSize: "2.0rem",
        })}
      />
    </div>
  );
}

export default ProgressCircle;
