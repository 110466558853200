import React, { useState } from "react";
import { Modal, Button, Toast, ToastContainer } from "react-bootstrap";
import Loader from "../components/loader";
import { deleteInstancia } from "../services/instanciaApi";

const DeleteInstanceModal = ({
  instanceName,
  instanceKey,
  tokenInstance,
  onDeleteSuccess,
}) => {
  const [showModal, setShowModal] = useState(true); // Modal inicia aberto
  const [loading, setLoading] = useState(false);
  const [errorMessage] = useState("");

  // Estados para o Toast
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("success"); // "success" ou "danger"

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDelete = async () => {
    setLoading(true);
  
    try {
      const response = await deleteInstancia(instanceKey, tokenInstance);
  
      if (response.success) {
        setToastMessage(`Instância "${instanceName}" deletada com sucesso!`);
        setShowToast(true);
  
        setTimeout(() => {
          handleCloseModal(); // Fecha o modal
          onDeleteSuccess(); // Atualiza o Dashboard
        }, 5000); // Dá tempo para o Toast ser exibido
      } else {
        setToastMessage(response.message || "Erro desconhecido ao deletar.");
        setToastVariant("danger");
        setShowToast(true);
      }
    } catch (error) {
      console.error("Erro ao deletar a instância:", error);
      setToastMessage("Erro ao deletar a instância.");
      setToastVariant("danger");
      setShowToast(true);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <>
      {/* Modal */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="top-modal"
        style={{ zIndex: 1050 }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Tem certeza de que deseja deletar a instância{" "}
            <strong>{instanceName}</strong>?
          </p>
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </Modal.Body>
        <Modal.Footer>
          {/* Remover o botão de Cancelar quando o Toast estiver sendo exibido */}
          {!showToast && (
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancelar
            </Button>
          )}
          <Button
            variant="danger"
            onClick={handleDelete}
            disabled={loading || showToast} // Desabilita o botão de Confirmar se estiver carregando ou se o Toast estiver sendo exibido
          >
            {loading ? "Deletando..." : "Confirmar"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Loader Overlay */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <Loader />
        </div>
      )}

      {/* Toast para mensagens de sucesso ou erro */}
      <ToastContainer position="bottom-end" className="p-3">
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={5000} // Aumente para 5 segundos para testes
          autohide
          bg={toastVariant}
        >
          <Toast.Body className="text-white">{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default DeleteInstanceModal;
