import axios from "axios";

// Criando uma instância do axios
const api = axios.create({
  baseURL: "https://megasenderbackend.tech/megasender", // URL base da API
  timeout: 10000, // Tempo limite para a requisição (10 segundos)
});

// Função para criar um fluxo (POST)
export const createFluxo = async (tokenInstance, days) => {
  try {
    // Verifica o token de usuário
    const token = localStorage.getItem("userToken");
    if (!token) {
      return {
        success: false,
        message:
          "Token de usuário não encontrado. Por favor, faça login novamente.",
      };
    }

    // Verifica se o token da instância foi fornecido
    if (!tokenInstance) {
      return {
        success: false,
        message: "Token da instância é obrigatório para criar o fluxo.",
      };
    }

    // Validação básica do número de dias
    if (!days || isNaN(days) || days < 1 || days > 100) {
      return {
        success: false,
        message: "O valor de 'days' deve ser um número entre 1 e 100.",
      };
    }

    // Dados do fluxo
    const data = {
      token_instance: tokenInstance, // Token da instância enviado dinamicamente
      days: days, // Valor dinâmico, enviado do input
      media: null, // Media será null
      type: "group", // Tipo fixo
      destinos: null, // Destinos será null
    };

    console.log("Dados sendo enviados para a API:", data); // Log para depuração

    // Chamando a API para criar o fluxo
    const response = await api.post("/create_fluxo", data, {
      headers: {
        Authorization: `Bearer ${token}`, // Incluindo o token do usuário no cabeçalho de autorização
      },
    });

    console.log("Resposta recebida da API:", response.data); // Log para depuração
    return { success: true, data: response.data }; // Retorna os dados da resposta
  } catch (error) {
    return handleApiError(error); // Trata erros de requisição
  }
};

// Função de tratamento de erros
const handleApiError = (error) => {
  let errorMessage = "Erro desconhecido.";
  if (error.response) {
    console.error("Erro do servidor:", error.response.data);
    errorMessage =
      error.response.data.detail ||
      error.response.data.message ||
      "Erro desconhecido no servidor.";
  } else if (error.request) {
    console.error("Sem resposta do servidor:", error.request);
    errorMessage =
      "Não foi possível se conectar ao servidor. Verifique sua conexão.";
  } else {
    console.error("Erro na configuração da requisição:", error.message);
    errorMessage = "Erro ao configurar a requisição: " + error.message;
  }

  // Tratamento adicional para erros de timeout
  if (error.code === "ECONNABORTED") {
    errorMessage = "A requisição excedeu o tempo limite. Tente novamente.";
  }

  return { success: false, message: errorMessage };
};

export default api;
