import React, { useEffect, useRef } from "react";
import "../css/advantages.css";
import Disparai from "../images/images-login/disparaai.png";
import Tim from "../images/images-login/tim.png";
import Dolphi from "../images/images-login/dolphin.png";

const AdvantagesCarousel = () => {
  const carouselInnerRef = useRef(null); // Referência para o conteúdo do carrossel
  const currentIndexRef = useRef(0); // Índice do item atual
  const isTransitioningRef = useRef(false); // Flag para verificar se está em transição

  const itemWidth = 380; // Largura dos itens
  const itemsToShow = 3; // Itens visíveis

  useEffect(() => {
    const carouselInner = carouselInnerRef.current;
    const items = carouselInner.querySelectorAll(".advantages-carousel-item");
    const totalItems = items.length;

    // Duplicar itens para criar o loop infinito
    for (let i = 0; i < itemsToShow; i++) {
      const cloneFirst = items[i].cloneNode(true);
      const cloneLast = items[totalItems - 1 - i].cloneNode(true);
      carouselInner.appendChild(cloneFirst);
      carouselInner.insertBefore(cloneLast, carouselInner.firstChild);
    }

    // Ajuste da largura total
    carouselInner.style.width = `${
      itemWidth * (totalItems + itemsToShow * 2)
    }px`;

    // Ajustar a posição inicial
    carouselInner.style.transform = `translateX(${-itemWidth * itemsToShow}px)`;

    // Função para atualizar a posição do carrossel
    function updateCarousel() {
      const offset = -(currentIndexRef.current + itemsToShow) * itemWidth;
      carouselInner.style.transition = "transform 0.5s ease-in-out";
      carouselInner.style.transform = `translateX(${offset}px)`;
    }

    // Função para resetar a posição após o loop
    function resetCarouselPosition() {
      carouselInner.style.transition = "none";
      if (currentIndexRef.current >= totalItems) {
        currentIndexRef.current = 0;
      } else if (currentIndexRef.current < 0) {
        currentIndexRef.current = totalItems - 1;
      }

      const offset = -(currentIndexRef.current + itemsToShow) * itemWidth;
      requestAnimationFrame(() => {
        carouselInner.style.transform = `translateX(${offset}px)`;
      });
    }

    // Função de navegação
    const navigateCarousel = (direction) => {
      if (isTransitioningRef.current) return; // Evita múltiplos cliques rápidos
      isTransitioningRef.current = true;

      if (direction === "next") {
        currentIndexRef.current++;
      } else {
        currentIndexRef.current--;
      }

      updateCarousel();

      // Após a transição, ajusta a posição
      carouselInner.addEventListener(
        "transitionend",
        () => {
          isTransitioningRef.current = false;
          if (currentIndexRef.current >= totalItems) {
            resetCarouselPosition();
          } else if (currentIndexRef.current < 0) {
            resetCarouselPosition();
          }
        },
        { once: true }
      );
    };

    // Configuração dos botões
    const prevButton = document.querySelector(".advantages-carousel-prev");
    const nextButton = document.querySelector(".advantages-carousel-next");

    prevButton.addEventListener("click", () => navigateCarousel("prev"));
    nextButton.addEventListener("click", () => navigateCarousel("next"));
  }, [itemWidth, itemsToShow]);

  return (
    <section
      id="section-advantages-carousel"
      className="section section-advantages"
    >
      <div className="advantages-carousel-container container">
        <div className="advantages-carousel">
          <h2>Empresas Parceiras do MegaSender</h2>
          <div className="advantages-carousel-inner" ref={carouselInnerRef}>
            <div className="advantages-carousel-item advantages-carousel-card text-center">
              <img
                src={Disparai}
                alt="Dispara AI"
                className="img-fluid"
                style={{ maxWidth: "172px", margin: "-4rem" }}
              />
              <p className="advantages-carousel-card-text">
                O <strong>Dispara AI</strong> é um software que possibilita
                fazer automatizações com intervalo e implementação de ChatGPT.
                Acesse agora mesmo com 7 dias grátis usando o cupom MegaSender.
              </p>
            </div>

            <div className="advantages-carousel-item advantages-carousel-card">
              <img
                src={Dolphi}
                alt="Dolphin"
                className="img-fluid"
                style={{ maxWidth: "321px", margin: "-8rem" }}
              />
              <p className="advantages-carousel-card-text">
                <strong>Dolphin</strong> é uma plataforma ótima para clientes
                que precisam fazer disparos e acessar bm e wpp em IP diferentes.
                Tenha 10 perfis grátis e use o proxy gratuito por tempo
                ilimitado. Acesse agora mesmo e, em sua assinatura, obtenha 15%
                de desconto em sua mensalidade com o cupom MegaSender.
              </p>
            </div>

            <div className="advantages-carousel-item advantages-carousel-card">
              <img
                src={Tim}
                alt="Tim"
                className="img-fluid"
                style={{ maxWidth: "250px", margin: "-6rem" }}
              />
              <p className="advantages-carousel-card-text">
                Apenas para clientes <strong>Tim</strong>
                <br />
                Compre chip já cadastrado diretamente com a gerente da Tim, com
                descontos exclusivos e atendimento 16 por 7.
              </p>
            </div>
          </div>
        </div>

        <div className="advantages-carousel-button-wrapper">
          <button className="advantages-carousel-prev advantages-carousel-button">
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.0435 3.4185L7.99999 12.625L18.0435 21.8315"
                stroke="currentColor"
                strokeWidth="6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button className="advantages-carousel-next advantages-carousel-button">
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.0435 3.4185L7.99999 12.625L18.0435 21.8315"
                stroke="currentColor"
                strokeWidth="6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};

export default AdvantagesCarousel;
