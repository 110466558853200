import React from "react";
import "../css/planos.css";
import CardPlanos from "../components/cardPlanos";
import ChatBot from "../components/botDialog";
import CardFooter from "../components/cardFooter";

const Plans = () => {
  const planItems = [
    {
      title: "Plano Iniciante 🚀",
      description: "1 instância",
      price: "R$ 100,00",
    },
    {
      title: "Plano Intermediário ❇",
      description: "5 instâncias (economia de 50%)",
      price: "R$ 250,00",
    },
    {
      title: "Plano Master 💰",
      description: "10 instâncias (economia de 65%)",
      price: "R$ 450,00",
    },
    {
      title: "Plano Bronze 🥉",
      description: "50 instâncias (economia de 80%)",
      price: "R$ 1.000,00",
    },
    {
      title: "Plano Prata 🥈",
      description: "100 instâncias (economia de 83%) - Mais vendido",
      price: "R$ 1.700,00",
    },
    {
      title: "Plano Ouro 🥇",
      description: "200 instâncias (economia de 84%)",
      price: "R$ 3.200,00",
    },
    {
      title: "Plano Diamante 💎",
      description: "500 instâncias (economia de 85%)",
      price: "R$ 7.500,00",
    },
    {
      title: "Plano Escala Infinita ♾",
      description: "Acima de 500 instâncias - Consultar vendedor",
      price: "Preço sob consulta",
    },
  ];

  const handleCardClick = (plan) => {
    // Link do WhatsApp com o número e a mensagem personalizada
    const whatsappUrl =
      "https://api.whatsapp.com/send/?phone=5511951598018&text=";
    const message = `Olá, gostaria de saber mais sobre o plano ${plan.title} (${plan.description}) com o preço de ${plan.price}.`;

    const whatsappLink = `${whatsappUrl}${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <section className="container-plano mx-auto py-10">
      <div className="header-Plano">
        <h2
          onClick={() => (window.location.href = "/login")}
          style={{ cursor: "pointer" }}
        >
          MegaSender
        </h2>
      </div>
      <h2 className="subtitle-plans">Conheça nossos planos</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {planItems.map((plan, index) => (
          <div
            key={index}
            className="plan-card-wrapper"
            onClick={() => handleCardClick(plan)}
          >
            <CardPlanos
              title={plan.title}
              description={plan.description}
              price={plan.price}
            />
          </div>
        ))}
      </div>
      <CardFooter />
      <ChatBot />
    </section>
  );
};

export default Plans;
