import { useNavigate } from 'react-router-dom';

const useNavigateWithItem = () => {
  const navigate = useNavigate();

  const navigateWithItem = (actionType, item) => {
    navigate(`/instanceView/${actionType}`, { state: { item } });
  };

  return navigateWithItem;
};

export default useNavigateWithItem;