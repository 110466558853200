import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Hook para navegação
import { loginUser } from "../services/loginApi";
import "../css/login.css";
import HeaderLogin from "../components/herderLogin";
import ComparisonCard from "../components/ComparisonCard";
import ButtonL from "../components/buttonLogin";
import CardMissao from "../components/cardMissao";
import SobreMega from "../components/sobreMega";
import CardServices from "../components/services";
import svgSuporte from "../images/images-login/suporte.svg";
import svgOuvidoria from "../images/images-login/ouvidoria.svg";
import svgHelp from "../images/images-login/help.svg";
import svgDestaque from "../images/images-login/destaque.svg";
import CardStatistics from "../components/statistc";
import statistica from "../images/images-login/estatistica.svg";
import Fire from "../images/images-login/fire.svg";
import relogio from "../images/images-login/Relogio.svg";
import CardMega from "../components/cardMega";
import CardFaqMega from "../components/cardFaqMega";
import iconSvg from "../images/images-login/icon.svg";
import AdvantagesCarousel from "../components/cardAdvantages";
import CardInfor from "../components/canaisInfor";
import CardRegist from "../components/cardRegist";
import CardFooter from "../components/cardFooter";
import ChatBot from "../components/botDialog";
import userPassword from "../images/images-login/usePasswrd.svg";
import userSvg from "../images/images-login/user.svg";
import ModalErro from "../modal/modalErro";

// Função para tratar o login
const Login = ({ setIsAuthenticated }) => {
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Função para tratar o login
  const handleLogin = async (e) => {
    e.preventDefault();

    // Verifica se os campos estão preenchidos
    if (!username || !password) {
      setError("Por favor, preencha todos os campos.");
      return;
    }

    try {
      // Substitua loginUser pela sua lógica real de autenticação
      const result = await loginUser(username, password);

      // Verifica se a resposta tem sucesso e um token
      if (result?.success && result?.token) {
        localStorage.setItem("userToken", result.token);
        if (result?.refresh_token) {
          localStorage.setItem("refresh_token", result.refresh_token);
        }
        setIsAuthenticated(true);
        navigate("/dashboard", { replace: true });
      } else {
        setError(result?.message || "Credenciais inválidas");
      }
    } catch (err) {
      // Tratamento de erro
      console.error("Erro no login:", err);
      setError(
        err.response?.data?.detail ||
          err.message ||
          "Erro ao tentar fazer login"
      );
    }
  };

  // Função para tratar alterações nos campos de entrada
  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    if (error) setError(""); // Limpar erro quando o usuário começar a digitar
  };

  return (
    <section id="sectio-main" className="section">
      <HeaderLogin title={"MegaSender"} />
      <div className="sectio-main">
        <section id="section-login" className="main-item">
          <h1 className="main-title">MegaAquecimento</h1>
          <p className="text description">
            O megasender é um software que aquece seu wpp com 1 clique e
            preparando seu wpp voce fazer disparos e receber leads em suas
            operações, potencializadno sua escala e contingencia. <br /> 24/7
            Suporte via whatsapp, telegram e discord para clientes vip Calls
            semanais em grupo para ajudar voce escalar ainda mais a sua operaçao
          </p>
          <div className="login-form-wrap">
            <form onSubmit={handleLogin}>
              <div className="form-container">
                <div className="form-input-container">
                  <span className="input-icon">
                    <img src={userSvg} alt="User Icon" />
                  </span>
                  <input
                    type="email"
                    className="form-input"
                    value={username}
                    onChange={handleInputChange(setUsername)}
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="form-input-container">
                  <span className="input-icon">
                    <img src={userPassword} alt="Password Icon" />
                  </span>
                  <input
                    type="password"
                    className="form-input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Senha"
                    required
                  />
                </div>
              </div>
              <div className="login-features">
                <label>
                  <input type="checkbox" id="remember" />
                  Lembrar-me
                </label>
              </div>
              <div className="form-button-wrap">
                <ButtonL children={"Login"} />
              </div>
              {/* Exibir modal de erro quando houver mensagem */}
              {/* Exibe o modal de erro */}
              {error && (
                <ModalErro message={error} onClose={() => setError("")} />
              )}

              <div className="have-acc">
                Não possui uma conta?{" "}
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=5511951598018&text=ol%C3%A1%20gostaria%20de%20criar%20um%20login%20no%20site."
                  rel="noreferrer"
                >
                  Crie um login.
                </a>
              </div>
            </form>
          </div>
        </section>
        <ComparisonCard />
      </div>
      <CardMissao />
      <SobreMega />
      <section id="section-services" className="section ">
        <div className="container services-container">
          <h2>O que oferecemos</h2>
          <div className="services-wrapper">
            <CardServices
              title={"Suporte 24/7"}
              content={
                " Tenha suporte Inteligente diretamente no Software ou humano através do WPP, Telegram e Discord."
              }
              svg={svgSuporte}
              position={"fist"}
            />

            <CardServices
              title={"Ouvimos você"}
              content={
                "Através de nossos canais de comunicação ajudamos voce a estruturar melhor a sua operação	e também a fazer mais vendas dos seus info produtos e mentoria,							com estratégias validadas"
              }
              svg={svgOuvidoria}
              position={"secound"}
            />
            <CardServices
              title={"Ajudamos você em sua operação"}
              content={
                "Através de nossos canais de comunicação ajudamos voce a estruturar melhor a sua operação e também a fazer mais vendas dos seus info produtos e mentoria,	com estratégias validadas"
              }
              svg={svgHelp}
              position={"third"}
            />
            <CardServices
              title={"Destaque"}
              content={
                "Mais de 1000 Pessoas usando os Nossos serviços diariamente, diversas 1 wpp aquecido a cada 30 segundos	dezenas de milhares de whatsapp sendo aquecido diariamente."
              }
              svg={svgDestaque}
              position={"fourth"}
            />
          </div>
        </div>
      </section>
      <section id="section-statistics" className="section section-statistics">
        <div className="container statistics-container">
          <h2>Estatisticas do mega</h2>
          <div className="statistics-card-wrapper">
            <CardStatistics
              svg={statistica}
              title={"Preços a partir de"}
              content={"0.1$/1k"}
            />
            <CardStatistics
              svg={Fire}
              title={"Quantos wpp já foram aquecidos"}
              content={"30.000"}
            />
            <CardStatistics
              svg={relogio}
              title={"Um wpp aquecido a cada"}
              content={"0.30Sec"}
            />
          </div>
        </div>
      </section>
      <section id="section-target" className="section section-target">
        <CardMega />
      </section>
      <section id="section-faq" className="section section-faq">
        <div className="container">
          <div className="faq-container">
            <div className="faq-wrapper">
              <h2>FAQ Megasender</h2>
              <div className="faq-accordion">
                <CardFaqMega
                  content={"Os números duram mais?"}
                  svg={iconSvg}
                  text={
                    "Sim, 100% dos WPP duram mais do que você usar um WPP novo."
                  }
                />
                <CardFaqMega
                  content={"Wpp vale a pena?"}
                  svg={iconSvg}
                  text={
                    "WPP te possibilita o lead mais barato, a conversão aumenta e você consegue salvar todos os leads para uma venda futura ou até mesmo um lançamento."
                  }
                />

                <CardFaqMega
                  content={"Como se registrar?"}
                  svg={iconSvg}
                  text={
                    "Apenas entre em contato com a Vera e em alguns instantes seu login já está pronto e validado."
                  }
                />
                <CardFaqMega
                  content={"O software é simples?"}
                  svg={iconSvg}
                  text={
                    "Sim, todos geralmente conseguem usar sem o passo a passo, pois é intuitivo, mas também temos vídeos ensinando a usar."
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <AdvantagesCarousel />
      <section id="section-blogs" className="section section-blogs">
        <CardInfor />
      </section>
      <section id="section-offer" className="sectionRegist">
        <CardRegist />
      </section>
      <section id="section-footer" className="sectionFooter">
        <CardFooter />
      </section>

      <ChatBot />
    </section>
  );
};

export default Login;
