import React, { useState } from "react";
import "../css/faqMega.css";

function CardFaqMega({ content, title, svg, text }) {
  const [isActive, setIsActive] = useState(false);

  const toggleFaqAccordion = () => {
    setIsActive(!isActive);
  };

  return (
    <div className="faq-accordion-item">
      <div
        className={`faq-accordion-header ${isActive ? "active" : ""}`}
        onClick={toggleFaqAccordion}
      >
        <span>{content}</span>
        <span className="faq-icon">
          <img src={svg} alt="FAQ icon" />
        </span>
      </div>

      <div className="faq-accordion-content">
        <p>{text}</p>
      </div>
    </div>
  );
}

export default CardFaqMega;
