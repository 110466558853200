import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo_imagem from "../images/logo-megasender-sidebar.svg";
import "../css/sidebar.css";

const Sidebar = ({ setIsAuthenticated }) => {
  const navigate = useNavigate(); // Usando o hook useNavigate
  const [userName, setUserName] = useState(""); // Estado para armazenar o nome do usuário

  const handleLogout = () => {
    // Limpa os tokens do localStorage
    localStorage.removeItem("userToken");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("userName"); // Remove o nome do usuário também

    // Atualiza o estado de autenticação
    setIsAuthenticated(false);

    // Redireciona para a página de login após o logout
    navigate("/login");

    // Força o recarregamento da página para reavaliar a autenticação
    window.location.reload(); // Recarrega a página e redireciona para a tela de login
  };

  // Verifica se o token de autenticação está presente no localStorage e recupera o nome do usuário
  useEffect(() => {
    const token = localStorage.getItem("userToken");

    if (!token) {
      // Se não houver token, redireciona para o login
      navigate("/login");
    }

    // Recupera o nome do usuário do localStorage
    const storedUserName = localStorage.getItem("userName") || "Usuário";
    setUserName(storedUserName);
  }, [navigate]); // A dependência 'navigate' assegura que o redirecionamento aconteça quando o componente for montado

  return (
    <div className="container-sidebar">
      {/* Seção do usuário */}
      {/* <div className="user-info">
        <i className="fa-solid fa-user-circle user-icon"></i>
        <p className="user-name">{userName}</p>
      </div> */}
      <div id="log-megasender-sidebar">
        <img src={logo_imagem} alt="Logo MegaSender" />
      </div>

      <div className="navgators">
        <Link draggable="false" className="link-formatted" to="/dashboard">
          <i className="fa-solid fa-house"></i> Instâncias
        </Link>
        <Link draggable="false" className="link-formatted" to="/dashboard">
          <i className="fa-solid fa-list-ul"></i> Histórico
        </Link>
        <Link draggable="false" className="link-formatted" to="/dashboard">
          <i className="fa-solid fa-newspaper"></i> Templates
        </Link>
        <Link draggable="false" className="link-formatted" to="/dashboard">
          <i className="fa-solid fa-chart-pie"></i> Métricas
        </Link>
        <Link draggable="false" className="link-formatted" to="/dashboard">
          <i className="fa-solid fa-star"></i> MegaClientes
        </Link>
        {/* Botão de logout */}
        <button className="logout-button" onClick={handleLogout}>
          <i className="fa-solid fa-sign-out-alt"></i> Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
