import "../css/comparisonCard.css";
import CardSimples from "./cardSimplesRed";

function ComparisonCard() {
  return (
    <section id="section-comparison" className="main-item">
      <div className="comparison-container container">
        <div className="comparison-wrapper">
          <div
            style={{
              display: "grid",
              gap: "0rem",
              marginTop: "-5rem",
              gridTemplateColumns: "3fr 1fr",
            }}
          >
            <h4 className="comparison-wrapper" style={{ textAlign: "right" }}>
              MegaSender
            </h4>
            <h4 className="comparison-wrapper">Concorrência</h4>
          </div>

          <CardSimples content={"Painel Simples"} />
          <CardSimples content={"Automático"} />
          <CardSimples content={"Aquecimento com um clique"} />
          <CardSimples content={"Mais duração de números"} />
          <CardSimples content={"Suporte via whatsapp, telegram"} />
        </div>
      </div>
    </section>
  );
}

export default ComparisonCard;
