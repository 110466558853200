import React from "react";
import "../css/cardRegist.css"; // Certifique-se de criar ou ajustar este arquivo CSS
import ButtonRegiste from "./cardButtaoRegiste";
function CardRegist() {
  return (
    <div className="container">
      <div className="offer-container">
        <h2>
          Se registre agora mesmo e não fique de fora do software mais completo
          e simples do mercado
        </h2>
        <div className="register-button">
        <ButtonRegiste />
        </div>

      </div>
      <div class="background-container">
        <div class="transparent-section"></div>
        <div class="dark-section"></div>
      </div>
    </div>
  );
}

export default CardRegist;
