

const CardDash = ({ title, icon, content }) => {
  return (
    <div className="square">
      <div className="title-square">
        <i className={`${icon} icon-painel`}></i>
        <span>{title}</span>
      </div>
      <h5 className="body-square">
          {content}
      </h5>
    </div>
  );
}


export default CardDash;