import "../css/instance-configure.css";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import InputNumberValidation from "../components/selectNumber";
import RadioActionFluxo from "../components/radioSelectActionFluxo";
import Tablefluxo from "../components/table_fluxo";
import ContainerTempates from "../components/galeryCardsTemplates";
import Personalizar from "../components/painelAvancado";
import Sidebar from "../components/sidebar";
import { getInstancias } from "../services/instanciaApi";
import { verifyInstance } from "../services/qrcodeApi";
import Loader from "../components/loader"; // Loader

const InstanceView = () => {
  const isComponentCAllowed = process.env.REACT_APP_ALLOW_COMPONENT === "plus";
  const [selectedValue, setSelectedValue] = useState("personalizar");
  const [instanceData, setInstanceData] = useState(null); // Todas as instâncias
  const [selectedInstance, setSelectedInstance] = useState(null); // Instância selecionada
  const [loading, setLoading] = useState(true); // Controla o estado de carregamento
  const [error, setError] = useState(null); // Armazenar erro se ocorrer
  const [isConnected, setIsConnected] = useState(false); // Estado para verificar conexão

  // Estado para imagem
  const [result, setImageUrl] = useState(null);
  const [block, setblock] = useState(null);
  const location = useLocation();
  const { item } = location.state || {}; // Dados passados via navegação

  const loadImageAndStatus = async (key, token, base_url) => {
    try {
      // Chama a API para verificar o status da instância
      const verifyResponse = await verifyInstance({ key, token, base_url });

      if (verifyResponse.success) {
        const imageUrl = verifyResponse.data.imageUrl || null;

        // Atualiza a URL da imagem
        setImageUrl(imageUrl);

        // Atualiza o status de conexão com base no campo 'status'
        setIsConnected(verifyResponse.data.status === "on");

        // Atualiza o estado do bloco
        setblock(imageUrl ? "block" : null);
      } else {
        console.error(
          "Erro na verificação da instância:",
          verifyResponse.message
        );

        // Redefine os estados caso a resposta não seja bem-sucedida
        setImageUrl(null);
        setIsConnected(false);
        setblock(null);
      }
    } catch (err) {
      console.error("Erro ao verificar a instância:", err);

      // Reseta os estados em caso de erro
      setImageUrl(null);
      setIsConnected(false);
      setblock(null);
    }
  };

  // Carregar as instâncias ao montar o componente
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getInstancias(); // Chama a função getInstancias
        if (result.success && result.data && result.data.instancias) {
          setInstanceData(result.data.instancias); // Armazena todas as instâncias
          // Seleciona a instância com base no `item` passado
          const selected = result.data.instancias.find(
            (inst) => inst.nome_instance === item?.nome_instance
          );
          setSelectedInstance(selected || null); // Define a instância selecionada

          // Se a instância for encontrada, carrega a imagem
          if (selected) {
            await loadImageAndStatus(
              selected.key,
              selected.token_instance,
              selected.base_url
            );
          }
        } else {
          setError("Erro ao carregar as instâncias.");
        }
      } catch (err) {
        console.error("Erro ao buscar instâncias:", err);
        setError("Erro ao buscar instâncias.");
      } finally {
        setLoading(false); // Finaliza o carregamento inicial
      }
    };

    fetchData(); // Faz a requisição inicial
  }, [item]);
  const formatarDataHora = (data) => {
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };

    try {
      const dataObj = new Date(data); // Converte a string em objeto Date
      return new Intl.DateTimeFormat("pt-BR", options).format(dataObj); // Formato brasileiro
    } catch (error) {
      console.error("Erro ao formatar a data:", error);
      return "Data inválida";
    }
  };

  
  useEffect(() => {
    if (selectedInstance) {
      // Chama apenas uma vez quando selectedInstance é atualizado
      loadImageAndStatus(
        selectedInstance.key,
        selectedInstance.token_instance,
        selectedInstance.base_url
      );
    }
  }, [selectedInstance]); // Executa sempre que selectedInstance muda

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <Loader />
      </div>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!selectedInstance) {
    return <p>Instância selecionada não encontrada.</p>;
  }

  return (
    <section className="dashboard-container-section">
      <Sidebar setIsAuthenticated={() => {}} />
      <div className="container-intance-view">
        <div className="container-cofigure-instance">
          <div className="formValidationContainer">
            {/* Passa as informações da instância selecionada */}
            <InputNumberValidation
              instanceKey={selectedInstance.key}
              instanceToken={selectedInstance.token_instance}
              instanceBase_url={selectedInstance.base_url}
              status={selectedInstance.status} // Passa o status da instância
            />
          </div>
          <span id="divisor"></span>
          {/* Renderiza o card apenas se houver imagem */}
          {result && (
            <div className="perfil_instance">
              <h4>
                <strong>Instância:</strong>{" "}
                {selectedInstance.nome_instance || "Sem nome"}
              </h4>
              <div className="imagen-container">
                <div>
                  <p>
                    <strong>Aquecimento:</strong>{" "}
                    {selectedInstance.tipo_aquecimento || "Não especificado"}
                  </p>
                  <p>
                    <strong>Data Início:</strong>{" "}
                    <i className="fa-regular fa-calendar"></i>{" "}
                    {selectedInstance.data_inicio
                      ? formatarDataHora(selectedInstance.data_inicio)
                      : "Não especificada"}
                  </p>
                  <p>
                    <strong>Data Término:</strong>{" "}
                    <i className="fa-regular fa-calendar"></i>{" "}
                    {selectedInstance.data_termino
                      ? formatarDataHora(selectedInstance.data_termino)
                      : "Não especificada"}
                  </p>
                </div>
                {typeof result === "string" && result.trim() !== "" && (
                  <div
                    style={{
                      width: "96px",
                      height: "96px",
                      backgroundColor: "#fff", // Cor de fundo
                      display: "flex",
                      marginLeft: "300px", // Empurra a imagem para a direita
                      alignItems: "center", // Centraliza verticalmente o conteúdo da imagem
                      justifyContent: "center", // Centraliza horizontalmente o conteúdo da imagem
                      overflow: "hidden", // Garante que a imagem não ultrapasse os limites
                    }}
                  >
                    <img
                      src={result}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="container-organization">
          <div className="container-header-actions">
            <RadioActionFluxo
              selectedValue={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
            />
          </div>
          <div className="rendered-component">
            {selectedValue === "personalizar" && (
              <Personalizar tokenInstance={selectedInstance?.token_instance} />
            )}
            {selectedValue === "templates" && <ContainerTempates />}
            {selectedValue === "avancado" && isComponentCAllowed && (
              <Tablefluxo />
            )}
          </div>

          {selectedValue === "avancado" && !isComponentCAllowed && (
            <p>Acesso negado ao Componente Avançado</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default InstanceView;
